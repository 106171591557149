import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    enableBackground: 'new 0 0 200 48',
  },
  path: {
    fill: '#7df3e1',
  },
  sto0: {
    fill: '#FFFFFF'
  }
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 200 48" className={classes.svg} xmlSpace="preserve">
    <g>
      <g>
        <path className={classes.sto0} d="M17.5,11.7l-3.8,7.5l-0.2-2.4c0,0-3.8,1.9-4.6,6.2c-0.3,1.8-0.4,4,1.1,6.7l-4.9,0.6l-1.5,4.9
          c-1.8-2.6-5.6-9.7-1.3-18.6c1.9-3.9,5.8-7.1,10-8.6c0,0-0.2-3-0.2-3L17.5,11.7L17.5,11.7z"/>
        <path className={classes.sto0} d="M34,32.8c-1.2,2.5-5,8.4-13.7,9.7c-4.9,0.7-9.8-1-13-3.5l-2.3,1.9l2.4-8.1l8.3-1.1l-1.8,1.5
          c0,0,3.4,1.9,7.1,0.6c1.8-0.6,3.7-1.6,5.2-4l2.4,3.8L34,32.8L34,32.8z"/>
        <path className={classes.sto0} d="M38.2,29.4l-8.4,1.2l-4.3-7.2l2,1c0,0,0.1-3-2.6-6c-1.4-1.5-3.4-2.7-6.4-2.9l2.2-4.4l-3.4-4
          C20.1,7,27.8,7.4,33,15.2c2.3,3.4,3.5,8.1,2.6,12.9L38.2,29.4L38.2,29.4z"/>
      </g>
    </g>
    </svg>
    
  );
};

export default LogoIcon;
