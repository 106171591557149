import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
    enableBackground: 'new 0 0 200 48',
  },
  path: {
    fill: '#7df3e1',
  },
  sto0: {
    fill: '#FFFFFF'
  }
});

const LogoFull = () => {
  const classes = useStyles();

  return (
    
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 200 48" className={classes.svg} xmlSpace="preserve">
    <g>
      <g>
        <path className={classes.sto0} d="M17.5,11.7l-3.8,7.5l-0.2-2.4c0,0-3.8,1.9-4.6,6.2c-0.3,1.8-0.4,4,1.1,6.7l-4.9,0.6l-1.5,4.9
          c-1.8-2.6-5.6-9.7-1.3-18.6c1.9-3.9,5.8-7.1,10-8.6c0,0-0.2-3-0.2-3L17.5,11.7L17.5,11.7z"/>
        <path className={classes.sto0} d="M34,32.8c-1.2,2.5-5,8.4-13.7,9.7c-4.9,0.7-9.8-1-13-3.5l-2.3,1.9l2.4-8.1l8.3-1.1l-1.8,1.5
          c0,0,3.4,1.9,7.1,0.6c1.8-0.6,3.7-1.6,5.2-4l2.4,3.8L34,32.8L34,32.8z"/>
        <path className={classes.sto0} d="M38.2,29.4l-8.4,1.2l-4.3-7.2l2,1c0,0,0.1-3-2.6-6c-1.4-1.5-3.4-2.7-6.4-2.9l2.2-4.4l-3.4-4
          C20.1,7,27.8,7.4,33,15.2c2.3,3.4,3.5,8.1,2.6,12.9L38.2,29.4L38.2,29.4z"/>
      </g>
      <path className={classes.sto0} d="M83.2,27.6L83,29.4h-5.3c-2.2,0-3.9-0.4-3.5-3l0.1-0.9l0.6-4l0.3-1.8l0.4-2.2l4.7,0l-0.3,2.2h4l-0.3,1.8h-4
        l-0.7,4.5c-0.2,1.2-0.1,1.5,1.2,1.5h0.3L83.2,27.6z M98.8,23l-0.4,2.5h-8l-0.1,0.8c-0.2,1.1,0.2,1.3,1.2,1.3h3.6l0,0h2.9l-0.3,1.8
        h-9.1c-2.3,0-3.4-0.7-3.1-3.3l0.5-3.1c0.4-2.3,1.5-3.3,3.8-3.3h5.9C98.3,19.8,99.2,20.5,98.8,23L98.8,23z M93.6,21.6h-1.9
        c-0.6,0-0.7,0.3-0.8,0.9l-0.2,1.2H94l0.2-1C94.3,22.1,94.3,21.6,93.6,21.6z M127,22.5l-0.7,4.5c0,0.1,0,0.2,0,0.3l-0.3,2.2h-4.3
        c-0.1-0.3-0.1-0.7-0.1-1c-0.4,0.8-1.1,1-1.9,1h-3.5c-1.9,0-2.9-0.6-2.6-2.7l0.3-1.7c0.2-1.5,1.4-2.2,2.8-2.2h4.5h0.3
        c0.5,0,0.8-0.1,0.9-0.6c0.1-0.6-0.4-0.7-0.9-0.7h-4.6c-0.1,0-0.1,0-0.1,0h-1.8l0.3-1.8h9l0.5,0C126.3,19.8,127.2,20.9,127,22.5
        L127,22.5z M121.9,24.7h-2.3c-0.9,0-1.2,0.4-1.3,1.2l-0.1,0.4c-0.1,0.8,0,1.2,0.9,1.2h1.2c0.9,0,1.2-0.4,1.3-1.2L121.9,24.7
        L121.9,24.7z M138.4,27.6L138.4,27.6h-2.7c-0.8,0-1.4-0.1-1.2-1l0.6-3.9c0.1-1,0.6-1.1,1.5-1.1h1.5l0,0h2.9l0.3-1.8h-7.2
        c-1.7,0-3.4,0.7-3.7,2.6l-0.7,4.4c-0.2,1.2,0.5,2.3,1.6,2.6c0.4,0,0.9,0.1,1.3,0.1h7.1l0.3-1.8L138.4,27.6L138.4,27.6z M155.3,29.4
        h4.8l1.5-9.7h-4.8L155.3,29.4L155.3,29.4z M157,18.7h4.7l0.4-2.4h-4.7L157,18.7L157,18.7z M175.3,19.8l-4.1,7.2l0,0l0,0l-1.8-7.2
        H164l2.7,9.7h5.2l5.6-9.7L175.3,19.8L175.3,19.8z M149.7,27.6L149.7,27.6h-0.3c-1.3,0-1.4-0.3-1.2-1.5l0.7-4.5h4l0.3-1.8h-4
        l0.3-2.2l-4.7,0l-0.4,2.2l-0.3,1.8l-0.6,4l-0.1,0.9c-0.4,2.6,1.3,3,3.5,3h5.3l0.3-1.8L149.7,27.6L149.7,27.6z M63.4,19.7h-4.2
        c0,0-1.5,9.7-1.5,9.7h4.8c0,0,1.1-6.9,1.1-6.9c0.1-0.6,0.3-0.9,1-0.9h1.4c0.9,0,0.9,0.4,0.8,1.2l-1,6.6h4.7c0,0,1-6.5,1-6.5
        c0.3-2-0.5-3.2-2.5-3.2h-3.5c-0.9,0-1.4,0.2-1.9,1L63.4,19.7L63.4,19.7z M51.1,16.4l-2.1,13.1h5l0,0l2-13.1L51.1,16.4L51.1,16.4z
         M107,19.8h-4.3l-1.5,9.7h4.7l0.9-5.6c0.1-1,0.9-1.5,1.8-1.5h1h2l0.4-2.6h-2.4c-1.3,0-2.1,0.2-2.8,1.6L107,19.8L107,19.8z
         M191.4,23l-0.4,2.5h-8l-0.1,0.8c-0.2,1.1,0.2,1.3,1.2,1.3h3.6l0,0h2.9l-0.3,1.8h-9.1c-2.3,0-3.4-0.7-3.1-3.3l0.5-3.1
        c0.4-2.3,1.5-3.3,3.8-3.3h5.9C190.9,19.8,191.8,20.5,191.4,23L191.4,23z M186.2,21.6h-1.9c-0.6,0-0.7,0.3-0.8,0.9l-0.2,1.2h3.3
        l0.2-1C186.9,22.1,186.9,21.6,186.2,21.6z"/>
      <g>
        <path className={classes.sto0} d="M196.4,8.1c1.8,0,3.3,1.5,3.3,3.4c0,1.9-1.5,3.4-3.3,3.4c-1.8,0-3.3-1.5-3.3-3.4C193,9.6,194.5,8.1,196.4,8.1
          L196.4,8.1z M196.4,8.7c-1.5,0-2.7,1.3-2.7,2.8c0,1.6,1.2,2.9,2.7,2.9c1.5,0,2.7-1.3,2.7-2.8C199.1,9.9,197.9,8.7,196.4,8.7
          L196.4,8.7z M195.8,13.5h-0.6V9.7c0.3-0.1,0.6-0.1,1.1-0.1c0.6,0,0.9,0.1,1.2,0.3c0.2,0.2,0.3,0.4,0.3,0.8c0,0.5-0.3,0.8-0.7,0.9
          v0c0.3,0.1,0.6,0.4,0.6,0.9c0.1,0.6,0.2,0.8,0.2,0.9h-0.6c-0.1-0.1-0.2-0.5-0.2-1c-0.1-0.5-0.3-0.7-0.8-0.7h-0.4L195.8,13.5
          L195.8,13.5z M195.8,11.4h0.4c0.5,0,0.9-0.2,0.9-0.6c0-0.3-0.2-0.7-0.9-0.7c-0.2,0-0.3,0-0.4,0V11.4z"/>
      </g>
    </g>
    </svg>
    
  );
};


// import InteractiveLogo from './logo/interactive.png';

// const LogoFull = () => {
//   return <img src={InteractiveLogo} width='170' />
// };

export default LogoFull;
