import {
    createBaseThemeOptions,
    createUnifiedTheme,
    genPageTheme,
    palettes,
  } from '@backstage/theme';

  export const interactiveTheme = createUnifiedTheme({
    ...createBaseThemeOptions({
      palette: {
        ...palettes.light,
        primary: {
          main: '#1200bf',
        },
        secondary: {
          main: '#0ae3db',
        },
        error: {
          main: '#8c4351',
        },
        warning: {
          main: '#8f5e15',
        },
        info: {
          main: '#34548a',
        },
        success: {
          main: '#485e30',
        },
        background: {
          default: '#ffffff',
          paper: '#ffffff',
        },
        banner: {
          info: '#34548a',
          error: '#8c4351',
          text: '#36405e',
          link: '#565a6e',
        },
        errorBackground: '#8c4351',
        warningBackground: '#8f5e15',
        infoBackground: '#36405e',
        navigation: {
          background: '#36405e',
          indicator: '#8f5e15',
          color: '#d5d6db',
          selectedColor: '#ffffff',
        },
      },
    }),
    defaultPageTheme: 'home',
    fontFamily: 'Segoe UI, Roboto, sans-serif',
    /* below drives the header colors */
    pageTheme: {
      home: genPageTheme({ colors: ['#1200bf'], shape: 'none' }),
    }
  });